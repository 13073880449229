import React, { useEffect } from "react"
import Carousel from "../Swiper/Swiper"

import Aos from "aos"
import "aos/dist/aos.css"

const Projects = () => {
	useEffect(() => {
		Aos.init({ duration: 2000 })
	}, [])
	return (
		<div data-aos='fade-up' data-aos-duration='3000'>
			<div className={"w-full md:h-screen"}>
				<div
					className={
						"container mx-auto xxs:px-4 md:px-16 flex flex-col justify-center w-full h-full"
					}>
					<div className={"pb-8"}>
						<p
							className={
								"text-2xl sm:text-3xl md:text-4xl font-bold text-backgroundColor inline border-b-[3px] border-primary"
							}>
							Projects
						</p>
						<p className={"py-4"}>&lt;/ Check My projects &gt;</p>
					</div>

					<Carousel />
				</div>
			</div>
		</div>
	)
}

export default Projects
