import React, { useEffect } from "react"

import Aos from "aos"
import "aos/dist/aos.css"

const AboutMe = () => {
	useEffect(() => {
		Aos.init({ duration: 2000 })
	}, [])

	return (
		<div data-aos='fade-up' data-aos-duration='2000'>
			<div
				className={
					"w-full min-h-[800px] flex justify-center items-center -mt-20"
				}>
				<div
					className={
						"container h-full mx-auto flex flex-col justify-center items-center -mt-20"
					}>
					<div className={"text-right pb-8"}>
						<p
							className={
								"text-3xl md:text-4xl font-bold text-backgroundColor inline border-b-2 border-primary"
							}>
							About Me
						</p>
					</div>

					<div className={"container"}>
						<p
							className={
								"text-[12px] leading-3 sm:text-base md:leading-8 md:text-xl max-w-s xxs:px-4 md:px-16"
							}>
							I love working on projects that challenge me and allow me to learn
							new technologies. I am also enthusiastic about performance
							optimisation and always strive to make my apps run smoothly on all
							devices. In my projects, I care about the readability of the code
							and the aesthetic design of the app. Outside of programming, I am
							interested in mountain climbing and Spanish league matches.
						</p>
					</div>
				</div>
			</div>
		</div>
	)
}

export default AboutMe
