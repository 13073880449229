import React, { useEffect } from "react"

import Aos from "aos"
import "aos/dist/aos.css"

import bootstrap from "../../assets/icons/bootstrap.png"
import cpp from "../../assets/icons/cpp.png"
import css from "../../assets/icons/css.png"
import git from "../../assets/icons/git.png"
import github from "../../assets/icons/github.png"
import html from "../../assets/icons/html.png"
import js from "../../assets/icons/js.png"
import php from "../../assets/icons/php.png"
import react from "../../assets/icons/react.png"
import responsive from "../../assets/icons/responsive.png"
import sass from "../../assets/icons/sass.png"
import tailwind from "../../assets/icons/tailwind.png"
import vue from "../../assets/icons/vue.png"

const Skills = () => {
	useEffect(() => {
		Aos.init({ duration: 2000 })
	}, [])

	return (
		<div data-aos='fade-up' data-aos-duration='3000'>
			<div
				className={
					"container mx-auto xxs:px-4 md:px-16 flex flex-col justify-center min-h-[800px] h-full"
				}>
				<div>
					<p
						className={
							"text-2xl sm:text-3xl md:text-4xl font-bold text-backgroundColor inline border-b-[3px] border-primary"
						}>
						My Skills
					</p>
					<p className={"py-4"}>
						{" "}
						&lt;/ I've worked with these technologies &gt;{" "}
					</p>
				</div>

				<div
					className={
						"w-full grid xxs:grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 text-center py-8 "
					}>
					<div
						className={
							"shadow-md shadow-[#dddddd] hover:scale-110 border-2 border-secondary  duration-300"
						}>
						<img
							src={html}
							className={"mx-auto w-16 mt-4 md:w-20"}
							alt='html icon'
						/>
						<p className={"py-4"}>HTML</p>
					</div>
					<div
						className={
							"shadow-md shadow-[#dddddd] hover:scale-110 border-2 border-secondary  duration-300"
						}>
						<img
							src={css}
							className={"mx-auto mt-4 w-16 md:w-20"}
							alt='css icon'
						/>
						<p className={"py-4"}>CSS</p>
					</div>
					<div
						className={
							"shadow-md shadow-[#dddddd] hover:scale-110 border-2 border-secondary  duration-300"
						}>
						<img
							src={js}
							className={"mx-auto w-16 mt-4 md:w-20"}
							alt='javascript icon'
						/>
						<p className={"py-4"}>JavaScript</p>
					</div>
					<div
						className={
							"shadow-md shadow-[#dddddd] hover:scale-110 border-2 border-secondary  duration-300"
						}>
						<img
							src={php}
							className={"mx-auto w-16 mt-4 md:w-20"}
							alt='php icon'
						/>
						<p className={"py-4"}>PHP</p>
					</div>
					<div
						className={
							"shadow-md shadow-[#dddddd] hover:scale-110 border-2 border-secondary  duration-300"
						}>
						<img
							src={cpp}
							className={"mx-auto w-16 mt-4 md:w-20"}
							alt='cpp icon'
						/>
						<p className={"py-4"}>C++</p>
					</div>
					<div
						className={
							"shadow-md shadow-[#dddddd] hover:scale-110 border-2 border-secondary  duration-300"
						}>
						<img
							src={sass}
							className={"mx-auto w-16 mt-4 md:w-20"}
							alt='sass icon'
						/>
						<p className={"py-4"}>SASS</p>
					</div>
					<div
						className={
							"shadow-md shadow-[#dddddd] hover:scale-110 border-2 border-secondary  duration-300"
						}>
						<img
							src={tailwind}
							className={"mx-auto w-16 mt-4 md:w-20"}
							alt='tailwind icon'
						/>
						<p className={"py-4"}>Tailwind</p>
					</div>
					<div
						className={
							"shadow-md shadow-[#dddddd] hover:scale-110 border-2 border-secondary  duration-300"
						}>
						<img
							src={bootstrap}
							className={"mx-auto w-[76px] mt-4 md:w-[95px]"}
							alt='bootstrap icon'
						/>
						<p className={"py-4"}>Bootstrap</p>
					</div>
					<div
						className={
							"shadow-md shadow-[#dddddd] hover:scale-110 border-2 border-secondary  duration-300"
						}>
						<img
							src={react}
							className={"mx-auto w-16 mt-4 md:w-20"}
							alt='react icon'
						/>
						<p className={"py-2"}>React JS</p>
					</div>
					<div
						className={
							"shadow-md shadow-[#dddddd] hover:scale-110 border-2 border-secondary  duration-300"
						}>
						<img
							src={responsive}
							className={"mx-auto w-16 mt-4 md:w-20"}
							alt='responsive icon'
						/>
						<p className={"py-4"}>RWD</p>
					</div>
					<div
						className={
							"shadow-md shadow-[#dddddd] hover:scale-110 border-2 border-secondary  duration-300"
						}>
						<img
							src={vue}
							className={"mx-auto w-16 mt-4 md:w-20"}
							alt='vue icon'
						/>
						<p className={"py-4"}>VUE</p>
					</div>
					<div
						className={
							"shadow-md shadow-[#dddddd] hover:scale-110 border-2 border-secondary  duration-300"
						}>
						<img
							src={git}
							className={"mx-auto w-16 mt-4 md:w-20"}
							alt='git icon'
						/>
						<p className={"py-4"}>GIT</p>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Skills
