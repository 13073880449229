import React from "react"
import { Swiper, SwiperSlide } from "swiper/react"
import "swiper/css"
import "swiper/css/free-mode"
import "swiper/css/pagination"
import { FreeMode, Pagination } from "swiper"
import CardSwiper from "./CardSwiper"

import project1 from "../../assets/projects/1projekt.jpg"
import project2 from "../../assets/projects/2projekt.jpg"
import project3 from "../../assets/projects/3projekt.jpg"
import project4 from "../../assets/projects/4projekt.jpg"
import project5 from "../../assets/projects/5projekt.jpg"
import project6 from "../../assets/projects/6projekt.jpg"
import project7 from "../../assets/projects/7projekt.jpg"
import project8 from "../../assets/projects/8projekt.jpg"
import project9 from "../../assets/projects/9projekt.jpg"

const Carousel = () => {
	return (
		<div className={""}>
			<Swiper
				freeMode={true}
				grabCursor={true}
				pagination={true}
				modules={[FreeMode, Pagination]}
				className='mySwiper'
				breakpoints={{
					0: {
						slidesPerView: 1,
						spaceBetween: 10,
					},
					480: {
						slidesPerView: 1,
						spaceBetween: 10,
					},
					768: {
						slidesPerView: 2,
						spaceBetween: 15,
					},
					976: {
						slidesPerView: 3,
						spaceBetween: 15,
					},
					1440: {
						slidesPerView: 4,
						spaceBetween: 30,
					},
				}}>
				<SwiperSlide>
					<CardSwiper
						name={"Personal Budget"}
						description={
							"Aplikacja pomagająca w prowadzeniu budżetu domowego napisana przy pomocy PHP, SQL oraz BOOTSTRAPA."
						}
						image={project1}
						hrefDemo='https://budget.pawel-burakowski.profesjonalnyprogramista.pl/index.php'
						hrefCode={
							"https://github.com/Pawel-Burakowski/Budget_App_Web_Bootstrap"
						}
					/>
				</SwiperSlide>

				<SwiperSlide>
					<CardSwiper
						name={"My Pokémon"}
						description={
							"Aplikacja napisana w VUE3 oparta na PokéAPI. Dzięki niej poznasz każdą postać ze świata pokemonów."
						}
						image={project9}
						hrefDemo='http://mypokemon.pl/'
						hrefCode={"https://github.com/Pawel-Burakowski/My_Pokemon"}
					/>
				</SwiperSlide>

				<SwiperSlide>
					<CardSwiper
						name={"Sklep Arthrohard"}
						description={
							"Aplikacja sklepu z bazą produktów pobieranych przez API napisaną w VUE3 oraz Tailwind CSS. "
						}
						image={project8}
						hrefDemo={"https://arthrohardpawelburakowski.pl/"}
						hrefCode={"https://github.com/Pawel-Burakowski/Arthrohard"}
					/>
				</SwiperSlide>

				<SwiperSlide>
					<CardSwiper
						name={"Quiz Game"}
						description={
							"Prosty quiz game z pytaniami z JavaScript napisany w czystym JS, HTML oraz CSS."
						}
						image={project2}
						hrefDemo={"http://prostyquizgame.pl/"}
						hrefCode={"https://github.com/Pawel-Burakowski/Quiz_Game"}
					/>
				</SwiperSlide>

				<SwiperSlide>
					<CardSwiper
						name={"Find Your Flight"}
						description={
							"Responsywna strona do wyszukiwania lotów zbudowana przy użyciu ReactJS, SASS, Aos animation oraz React Icons."
						}
						image={project4}
						hrefDemo={"https://pawel-burakowski.github.io/Find-Your-Flight/"}
						hrefCode={"https://github.com/Pawel-Burakowski/Find-Your-Flight"}
					/>
				</SwiperSlide>

				<SwiperSlide>
					<CardSwiper
						name={"E Commerce Shop"}
						description={
							"Responsywna strona z artykułami domowymi stworzona w ReactJS oraz SCSS."
						}
						image={project3}
						hrefDemo={
							"https://pawel-burakowski.github.io/Furniture-and-Homeware/"
						}
						hrefCode={
							"https://github.com/Pawel-Burakowski/Furniture-and-Homeware"
						}
					/>
				</SwiperSlide>

				<SwiperSlide>
					<CardSwiper
						name={"Ksiażka adresowa"}
						description={"Ksiażka adresowa napisana w języku C++"}
						image={project5}
						hrefDemo={
							"https://github.com/Pawel-Burakowski/Ksiazka-adresowa#readme"
						}
						hrefCode={"https://github.com/Pawel-Burakowski/Ksiazka-adresowa"}
					/>
				</SwiperSlide>

				<SwiperSlide>
					<CardSwiper
						name={"Ksiażka adresowa obiektowo"}
						description={"Ksiażka adresowa napisana obiektowo w języku C++"}
						image={project6}
						hrefDemo={
							"https://github.com/Pawel-Burakowski/Ksiazka-adresowa-obiektowo/blob/Master/README.md"
						}
						hrefCode={
							"https://github.com/Pawel-Burakowski/Ksiazka-adresowa-obiektowo"
						}
					/>
				</SwiperSlide>

				<SwiperSlide>
					<CardSwiper
						name={"Aplikacja budżet C++"}
						description={
							"Aplikacja do prowadzenia budżetu domowego napisana w języku C++"
						}
						image={project7}
						hrefDemo={
							"https://github.com/Pawel-Burakowski/Budget_App/blob/main/README.md"
						}
						hrefCode={"https://github.com/Pawel-Burakowski/Budget_App"}
					/>
				</SwiperSlide>
			</Swiper>
		</div>
	)
}

export default Carousel
