import React from "react"

const CardSwiper = ({ image, hrefDemo, hrefCode, name, description }) => {
	return (
		<div className={"text-center pb-10"}>
			<div
				className={
					"shadow-md shadow-backgroundColor group flex justify-center items-center mx-auto content-div"
				}>
				<img src={image} className={"border-1"} />
			</div>

			<div className={""}>
				<div>
					<h2 className={"my-2 text-primary"}>{name}</h2>
					<p className={"h-24 mb-4 xl:mb-2"}>{description}</p>
				</div>

				<button
					type='button'
					className={
						"border-2 border-primary hover:bg-primary duration-200 text-blackColor px-5 font-medium cursor-pointer z-50 text-lg"
					}>
					<a href={hrefDemo} target='_blank'>
						View
					</a>
				</button>
				<button
					type='button'
					className={
						"border-2 border-primary hover:bg-primary duration-200 text-blackColor px-5 font-medium cursor-pointer ml-3 z-50 text-lg"
					}>
					<a href={hrefCode} target='_blank'>
						Code
					</a>
				</button>
			</div>
		</div>
	)
}

export default CardSwiper
